@font-face {
  font-family: 'FontMoreAwesome';
  src: url('/assets/webfonts/Fontmoreawesome-Regular.otf');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

.fa-blibli:before {
  font-family: 'FontMoreAwesome';
  content: "A";
}

.fa-bukalapak:before {
  font-family: 'FontMoreAwesome';
  content: "B";
}

.fa-jd:before {
  font-family: 'FontMoreAwesome';
  content: "C";
}

.fa-lazada:before {
  font-family: 'FontMoreAwesome';
  content: "D";
}

.fa-shopee:before {
  font-family: 'FontMoreAwesome';
  content: "E";
}

.fa-tokopedia:before {
  font-family: 'FontMoreAwesome';
  content: "F";
}

.fa-woo:before {
  font-family: 'FontMoreAwesome';
  content: "G";
}
