@import url('./assets/css/all.min.css');
@import url('./assets/css/fma.scss');
@import "~bootstrap/dist/css/bootstrap";

@font-face
{
    font-family: 'slick';
    font-weight: normal;
    font-style: normal;
    src: url('../node_modules/slick-carousel/slick/fonts/slick.eot');
    src: url('../node_modules/slick-carousel/slick/fonts/slick.eot?#iefix') format('embedded-opentype'), url('../node_modules/slick-carousel/slick/fonts/slick.woff') format('woff'), url('../node_modules/slick-carousel/slick/fonts/slick.ttf') format('truetype'), url('../node_modules/slick-carousel/slick/fonts/slick.svg#slick') format('svg');
    font-display: swap;
}
body {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    color: #0A4D78;
    background: linear-gradient(180deg, #f0f8ff -75.8%, rgba(240, 248, 255, 0) 100%);
    background-image: url('assets/backgrounds/a.svg'), url('assets/backgrounds/b.svg'), url('assets/backgrounds/c.svg'),
        url('assets/backgrounds/d.svg');
    background-position: right top, left 30%, right 60%, left bottom;
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
}

header {
    margin-bottom: 3rem;
}

.mainLogo {
    color: #0a568e;
    text-align: center;
    margin-bottom: 5rem;
}

.mainLogo img {
    width: 26vw;
}

@media only screen and (max-width: 576px) {
    .mainLogo img {
        width: 80vw;
    }
}

.title {
    font-size: 2.25rem;
    font-weight: 700;
    margin-bottom: 40px;
    text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
}

.subtitle {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 40px;
    text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
}

.h4 {
    font-size: 1.875rem;
    font-weight: 700;
    margin-bottom: 40px;
    text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
}

p {
    font-size: 1.3125rem;
    /* text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25); */
}

ol, ul, dl {
    font-size: 1.0625rem;
    /* text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25); */
}

#snk {
    cursor: pointer;
    text-decoration: underline;
}

.btn-custom1 {
    border: 0;
    color: white;
    background: linear-gradient(114.03deg, #a02d8d -23.61%, #e63e6d 143.8%) no-repeat;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    font-size: 18px;
    font-weight: 800;
}

.btn-custom2 {
    border: 0;
    color: white;
    background: linear-gradient(114.03deg, #c03508 -23.61%, #fd8c04 143.8%) no-repeat;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    font-size: 18px;
    font-weight: 800;
}

.btn-custom3 {
    border: 0;
    color: white;
    background: linear-gradient(114.03deg, #0a568e -23.61%, #7fd34f 143.8%) no-repeat;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.9);
    border-radius: 10px;
    font-size: 18px;
    font-weight: 800;
}

.dropdown-item:active {
    background-color: transparent;
}
@media (min-width: 1200px) {
    .modal-xl,
    .modal-custom {
        max-width: 100% !important;
        width: 100% !important;
    }
}

@media (min-width: 768px) {
    .modal-xl > .modal-dialog {
        max-width: 90% !important;
        width: 90% !important;
    }
}
/* Carousel */
.carousel {
    background: linear-gradient(169.11deg, #ffffff 0.48%, rgba(192, 224, 255, 0.24) 119.65%);
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(70px);
    border-radius: 48px;
    display: block;
    height: auto;
    border: 1px solid #3aa5fd50;
}

.carousel-inner {
    padding: 2rem;
}

.carousel-item {
    padding: 0 1rem;
}

.carousel-indicators {
    top: auto;
    bottom: -70px;
}

.carousel-indicators li {
    background: #fff !important;
    border: 1px solid #00000032;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    margin: 0 9px;
}

.carousel-indicators .active {
    background: #7fd34f !important;
}

/* Slick Carousel */
.slick-img {
    width: 12.5rem;
    height: auto;
    margin: 30px;
}

.slick-dots li button {
    background: #fff !important;
    border: 1px solid #00000032 !important;
    border-radius: 50%;
    width: 14px !important;
    height: 14px !important;
    padding: unset !important;
    opacity: 0.5;
    transition: opacity 0.6 ease;
}

.slick-dots li button:before {
    content: none !important;
}

.slick-dots li.slick-active button {
    background: #7fd34f !important;
    opacity: 1;
}

.slick-slide {
    filter: opacity(0.6);
    transform: scale(0.6);
    transition: linear 0.3s;
}

.partner-carousel .slick-center {
    filter: opacity(1);
    transform: scale(1.2);
}
